<template>
    <!-- 查看排期 -->
    <div>
        <calendar-control></calendar-control>
    </div>
</template>

<script>
export default {
    components: {
        calendarControl: () => import("@/components/calendarControl"),
    },
}
</script>

<style scoped>

</style>
